#tg_toc_id {
  background-color: #FFFFFF;
  padding-bottom: 5px !important;
  overflow-x: hidden !important;
}
#tg_toc_id ol {
  padding-left: 20px;
}
#tg_toc_id p, #tg_toc_id reference {
  display: none;
}
#tg_toc_id section {
  display: none;
}
#tg_toc_id section.active {
  display: block;
  margin-left: 15px;
}
#tg_toc_id section > ditatile {
  margin-left: 15px;
}
#tg_toc_id .tandf {
  display: none !important;
}
#tg_toc_id .tandf.show { /*toggle tables and figures links in line manually*/
  display: block !important;
}
#tg_toc_id .tandf ditatitle {
  display: block !important;
}
#tg_toc_id [href="abg/c_abg16-c47-s1.dita"] {
  display: block !important;
}
#tg_toc_id [href="c_post%E2%80%93sexual_assault_antimicrobial_prophylaxis.dita"] {
  background-image: inherit;
  margin-left: -10px;
}

mapref > bookmap > chapter {
  padding-left: 10px;
  display: none;
}
mapref > bookmap > chapter > topicref {
  padding-left: 10px;
  display: block;
}
mapref > bookmap > chapter.active {
  display: block;
}
mapref[children="0"] { /*maprefs will never have 0 children...fix in preprocessing.*/
  margin-left: 0px;
  padding-right: 5px !important;
}

[children="0"] {
  margin-left: 10px;
  display: block;
}

ditatitle {
  display: block;
  cursor: pointer;
  padding: 3px 3px 3px 15px;
  font-weight: bold;
  font-size: 13px;
  font-family: "FranklinGothicITCbyBT-Book", Helvetica, sans-serif;
}

chapter > concept > ditatitle {
  display: none;
}

.dxp-header {
  display: none !important;
}

fig ditatitle {
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  padding-bottom: 8px;
  margin-bottom: 1px;
  color: #333333;
  font-family: "FranklinGothicITCbyBT-Demi", sans-serif;
}
fig [outputclass=hw_boxed] {
  padding: 5px 15px !important;
}
fig [outputclass=hw_boxed] ditatitle {
  padding: 6px 3px 8px 0px !important;
}

#expertgroupId {
  display: block;
  max-height: 500px !important;
  overflow-y: scroll;
}
#expertgroupId p {
  font-size: 14px;
  margin: 0 0 20px;
  line-height: 20px;
  color: #414042;
}
#expertgroupId ditatitle {
  font-size: 20px;
  color: #414042;
  padding: 0px !important;
}

topicref[children="0"] {
  background-color: #ffffff;
}
topicref[children="0"]:not(.active) ditatile:hover {
  background-color: #E7ECFA;
}
topicref[children="0"].active {
  background-color: #DEF6FF;
}
topicref[children="0"].active ditatitle {
  background-color: #def6ff;
}
topicref[children="0"] > concept > ditatitle {
  background-color: #ffffff;
}
topicref:not([children="0"]) {
  background-image: url("../assets/images/arw_strgt.png");
  background-repeat: no-repeat;
  background-position: 0px 8px;
  margin-left: 10px;
  display: block;
  background-color: #ffffff;
}
topicref:not([children="0"]) > topicref, topicref:not([children="0"]) > mapref {
  display: none;
}
topicref:not([children="0"]).active {
  background-image: url("../assets/images/arw_tilt.png");
  background-repeat: no-repeat;
  background-position: 0px 8px;
  margin-left: 10px;
}
topicref:not([children="0"]).active > topicref, topicref:not([children="0"]).active mapref {
  display: block;
}
topicref[format=ditamap], topicref[format=ditamap].active {
  background-image: none;
}
topicref > mapref,
topicref > topicref,
topicref > topicref > topicref,
topicref > topicref > topicref > topicref,
topicref > topicref > topicref > topicref > topicref,
topicref > topicref > topicref > topicref > topicref > topicref {
  padding-left: 10px;
}

bookmap > ditatitle {
  background-image: url("../assets/images/arw_strgt.png");
  background-repeat: no-repeat;
  background-position: 0px 8px;
  margin-left: 10px;
  display: block;
  background-color: #ffffff;
}
bookmap > ditatitle.active {
  background-image: url("../assets/images/arw_tilt.png");
  background-repeat: no-repeat;
  background-position: 0px 8px;
  margin-left: 10px;
}

h4.dartsectiondisplay {
  margin-left: 0px !important;
}

#contentId table {
  width: 100%;
  background: #ecf6f7;
  font-size: 15px;
  color: #181818;
  margin-bottom: 20px;
}
#contentId table note {
  margin: 0px !important;
  border: 0 !important;
}
#contentId thead td {
  font-weight: bold;
  background-color: rgb(199, 227, 235);
}
#contentId tr[outputclass=tgl_tableHeader], #contentId tr[outputclass="tgl_tableHeader "], #contentId tr[outputclass=tableSubheader] {
  background-color: #c7e3eb;
}
#contentId td {
  vertical-align: top;
  border: 2px solid #fff;
  padding: 6px;
  line-height: 1.42857143;
}
#contentId td b {
  font-size: 14px;
}
#contentId td > note > p {
  font-size: 12px !important;
  padding: 10px 5px 0px 5px !important;
  line-height: 1;
}
#contentId .tandf ditatitle {
  display: block !important;
}
#contentId concept > ditatitle {
  display: none;
}
#contentId prolog, #contentId [outputclass=docversion], #contentId colapsecontent {
  display: none;
}
#contentId ditacontent > topic > ditatitle {
  display: none;
}
#contentId ditacontent > topic > section > ditatitle {
  font-size: 13px !important;
}
#contentId section > ditatitle {
  font-size: 15px !important;
  color: #333333;
  font-weight: 700;
  line-height: 24px;
  margin-bottom: 8px;
  border: none;
  margin: 0 0 8px 0;
  padding-left: 0px;
  font-family: "FranklinGothicITCbyBT-Demi", sans-serif;
}
#contentId topic > ditatitle {
  font-family: "FranklinGothicITCbyBT-Demi", sans-serif;
  font-size: 24px;
  color: #333333;
  padding: 0;
  margin-top: 0;
  line-height: 28px;
  margin-bottom: 10px;
  border-bottom: none;
  font-weight: 500;
}
#contentId ditatitle[outputclass=hw_tt], #contentId ditatitle[id^=table-] {
  font-size: 16px !important;
  color: #333333 !important;
  font-weight: 700 !important;
  padding-bottom: 8px !important;
  margin-bottom: 1px !important;
  line-height: 20px !important;
  font-family: "FranklinGothicITCbyBT-Demi", sans-serif !important;
  display: block !important;
}
#contentId ditatitle[id^=table-], #contentId ditatitle[outputclass=hw_tt] {
  padding-left: 0px !important;
  padding-right: 0px !important;
}
#contentId term {
  display: inline;
  cursor: wait;
}

#portal .modal-xl {
  width: 95%;
  height: 95%;
}
#portal .modal-xl .modal-dialog {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}
#portal .modal-xl .modal-content {
  height: auto;
  min-height: 100%;
  border-radius: 0;
}

booktitle,
ph[outputclass=tgl_indication],
topichead,
cite,
related-links,
[href="drug_key_links_trim.dita"],
glossterm,
map > ditatitle {
  display: none;
}

[href$=tables_and_figures] {
  display: block !important;
  padding-left: 0px;
}

[navtitle=Tables] {
  margin-left: 0px !important;
}

.hideprint {
  display: none !important;
}

indexterm {
  visibility: hidden;
  font-size: 1px;
}
indexterm::after {
  content: ", ";
}

colapsecontent.active {
  display: block;
}

.colapsecontent.dartsectiondisplay {
  display: none;
}
.colapsecontent.dartsectiondisplay.active {
  display: block;
}

.showtandfsection {
  width: -webkit-fill-available !important;
  overflow: hidden !important;
  word-wrap: normal !important;
  display: block;
}

.col-lg-9, .col-md-9, .col-sm-9, .col-xs-12 {
  position: relative !important;
  padding-left: 7.5px;
  padding-right: 7.5px;
}

.mobilePanel .slider-arrow {
  display: none !important;
}

.greyboxTopic {
  margin-right: 15px !important;
}

.dartsectiondisplay {
  transition: max-height 0.5s ease-in-out;
}
.dartsectiondisplay .topic-icons {
  margin-bottom: 5px !important;
}
.dartsectiondisplay .active {
  transition: max-height 0.5s ease-in-out;
}

td > p[outputclass=tgl_tableHeader] {
  background-color: #c7e3eb;
  margin: -6px;
  padding: 6px;
  font-weight: bold;
}
td > p[outputclass=tgl_p] {
  margin: 3px 6px 3px !important;
}
td [outputclass="pull-right icons marginTB3px"] {
  float: left !important;
  margin-right: 10px !important;
  display: block;
}

note {
  margin-bottom: 20px;
  background: #f5e0db;
  color: #2f2f2f;
  padding: 6px;
  border: 1px solid transparent;
  border-radius: 4px;
  display: block;
  font-family: "FranklinGothicITCbyBT-Book", Helvetica, sans-serif;
  font-size: 14px;
}

fn {
  font-size: 12px;
}
fn:not([fn-type=margin-note]) span {
  font-size: 12px !important;
}

table note, fig note {
  font-size: 12px;
  line-height: 22px;
  background: #e6e6e6;
  padding: 3px 6px;
  width: 100%;
  display: block;
  border-top: 3px solid #FFF;
  margin-top: -6px;
  margin-bottom: 0px;
}

div#TOC_ID.active {
  height: 500px;
  overflow: auto;
  padding-right: 10px;
}
div#contentId.active {
  height: 550px;
  overflow: auto;
  padding-right: 10px;
}
div[outputclass=tgl_blueBox] {
  border: none;
  padding: 10px 40px; /* fixed padding for left numbered bullets on blueBox */
  color: #414042;
  background: #d9f1f3;
  font-family: inherit;
  margin-bottom: 10px;
  font-size: 14px;
  padding-right: 60px;
}
div.epinvisible {
  background: #efefef none repeat scroll 0 0;
  border: 1px solid #cbcbcb;
  display: none;
  margin-top: -5px !important;
  max-width: 50%;
  padding: 15px 10px;
  position: absolute;
  right: -120px;
  width: inherit;
  word-break: break-all;
  z-index: 99;
}
div.epinvisible a {
  display: block;
  padding: 4px;
}
div.epinvisible a:hover {
  background: #ccc;
}
div.pbsbutton {
  cursor: pointer;
  background-color: lemonchiffon;
  border: 1px solid lightgrey;
  font-family: Transit, Verdana, Arial, Helvetica, sans-serif;
  font-size: 80%;
  font-weight: bold;
  font-style: normal;
  color: #000080;
}
div#preload {
  display: none;
}
div.dot .dot {
  color: rgba(0, 0, 0, 0.4588235294);
  width: 21px;
  height: 10px;
  --d:radial-gradient(farthest-side,currentColor 90%,#0000);
  background: var(--d), var(--d), var(--d);
  background-size: 5px 5px;
  background-repeat: no-repeat;
  animation: m 1s infinite alternate;
  float: left;
  margin-top: 8px;
}

[outputclass=tgl_blueBox] p {
  padding: 5px 0px 15px 0px !important;
}
[outputclass=tgl_blueBox] > div {
  padding-left: 80px;
}

fig[outputclass=hw_boxed],
fig {
  border: solid 1px;
  margin: 0 0 15px 0;
  padding: 5px;
  display: inline-block;
}

ph[outputclass=listBullet], ph[outputclass=tgl_recorank] {
  width: 25px;
  font-weight: 700;
  height: 25px;
  border-radius: 40px;
  font-size: 14px;
  color: #000;
  line-height: 24px;
  text-align: center;
  text-decoration: none;
  text-shadow: 0 1px 0 #fff;
  background: #fff;
  float: left;
  font-family: "DINNeuzeitGrotesk-Light", sans-serif;
  padding-right: 0px;
  margin-right: 10px;
  margin-left: -30px;
}

refbody {
  font-size: 14px;
  line-height: 22px;
  background: #e6e6e6;
  padding: 3px 6px;
  width: 100%;
  display: block;
  margin-bottom: 6px;
  color: #414042;
  padding: 5px 15px 5px 15px !important;
}
refbody section {
  overflow: hidden;
}
refbody ul {
  list-style-type: none !important;
  padding-left: unset;
  font-family: "FranklinGothicITCbyBT-Book", sans-serif;
}
refbody li {
  margin-bottom: 10px !important;
}
refbody img.figure {
  display: inline-block;
  content: url("../assets/images/pubmed.gif");
}

reference > ditatitle {
  font-size: 14px;
  line-height: 22px;
  background: #e6e6e6;
  padding: 3px 6px;
  width: 100%;
  display: block;
  margin-bottom: 6px;
  color: #414042;
  padding: 5px 15px 5px 15px !important;
  font-family: "FranklinGothicITCbyBT-Demi", sans-serif;
  font-size: 18px;
  line-height: 22px;
  margin-bottom: 0px;
  font-weight: bold;
  padding: 12px 15px 8px 15px !important;
}

xref {
  cursor: pointer;
  color: #003366;
  text-decoration: underline;
}
xref.active {
  cursor: pointer !important;
}

link, linktext {
  cursor: pointer;
}

term.active {
  cursor: pointer !important;
}

.pubmedlinks::after {
  content: url("../assets/images/pubmed.gif");
}

indexterm {
  visibility: hidden;
  font-size: 1px;
}
indexterm::after {
  content: ", ";
}

[outputclass="drugcodes tgl_pbs"],
[outputclass=tgl_pbs],
[outputclass="tgl_a tgl_pbs"] {
  content: url("../assets/images/tg_dosage_pbs.svg");
  height: 25px;
  width: 25px;
  display: inline;
}

[outputclass=tgl_preg] {
  content: url("../assets/images/tg_dosage_pregnancy.svg");
  height: 25px;
  width: 25px;
  display: inline;
}

[outputclass=tgl_breast] {
  content: url("../assets/images/tg_dosage_breastfeeding.svg");
  height: 25px;
  width: 25px;
  display: inline;
}

[outputclass=tgl_tableSubheader] {
  background-color: #d9f1f3;
}

ph[outputclass="pull-right icons marginTB3px"],
ph[outputclass="pull-right icons"],
ph[outputclass=pull-right] {
  float: right;
  vertical-align: top;
  text-align: right;
  margin-right: -45px;
}

ph[outputclass="pull-right icons marginTB3px"] term,
ph[outputclass="pull-right icons"] term,
ph[outputclass=pull-right] term {
  cursor: pointer;
}

ph[outputclass="pull-right icons marginTB3px"] xref,
ph[outputclass="pull-right icons"] xref,
ph[outputclass=pull-right] xref,
ph[outputclass="pull-right icons marginTB3px"] term,
ph[outputclass="pull-right icons"] term,
ph[outputclass=pull-right] term {
  margin-left: 8px;
  margin-top: 5px;
}

.dartmodal {
  display: none;
}
.dartmodal.active {
  display: block;
}

.dartmodalbox {
  background-color: #fff;
  border: 1px solid #ccc;
  display: block;
  left: 10%;
  padding: 15px;
  position: fixed;
  top: 25%;
  bottom: 25%;
  width: 80%;
  box-shadow: 1px 1px 10px 3px #ccc;
  z-index: 2;
}
.dartmodalbox ditacontent p > xref {
  color: #036 !important;
}

.dartmodalboxmobile {
  background-color: #fff;
  border: 1px solid #ccc;
  display: block;
  left: 10%;
  padding: 15px;
  position: fixed;
  top: 10%;
  bottom: 0%;
  width: 80%;
  box-shadow: 1px 1px 10px 3px #ccc;
  z-index: 2;
}

.closemodal {
  float: right;
}

.dartmodalback {
  width: 100%;
  background-color: #000;
  height: 100%;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 1;
  opacity: 0.7;
}

.dartframe {
  float: right;
  display: none;
}

span .dottext {
  float: left;
  margin-right: 5px;
  color: white;
}
span .dot .dot {
  color: rgba(0, 0, 0, 0.4588235294);
  width: 21px;
  height: 10px;
  --d:radial-gradient(farthest-side,currentColor 90%,#0000);
  background: var(--d), var(--d), var(--d);
  background-size: 5px 5px;
  background-repeat: no-repeat;
  animation: m 1s infinite alternate;
  float: left;
  margin-top: 8px;
}
span.closefull {
  text-align: center;
  color: red;
}
span.info {
  color: blue;
  text-decoration: underline;
  font-family: "FranklinGothicITCbyBT-Book", sans-serif;
  font-size: 8pt;
}
span.pbsexpand:hover {
  background-repeat: no-repeat;
  background-position: center;
}
span.pbsclose:hover {
  background-repeat: no-repeat;
  background-position: center;
}

.superscript {
  vertical-align: super;
  font-size: 80%;
}

@keyframes m {
  0% {
    background-position: 0% 50%, 50% 50%, 100% 50%;
  }
  20% {
    background-position: 0% 0, 50% 50%, 100% 50%;
  }
  40% {
    background-position: 0% 100%, 50% 0, 100% 50%;
  }
  60% {
    background-position: 0% 50%, 50% 100%, 100% 0;
  }
  80% {
    background-position: 0% 50%, 50% 50%, 100% 100%;
  }
  100% {
    background-position: 0% 50%, 50% 50%, 100% 50%;
  }
}
#dart {
  margin: 5px;
  color: red;
}

select.ccmsversion {
  width: -webkit-fill-available;
  text-align: center;
  margin: auto 5px;
  border: 1px solid #ccc;
  display: none;
}

fig, image {
  display: inline-block;
}

.qatools {
  padding: 10px;
  border: 1px solid #CCC;
  margin: 5px;
  text-align: center;
}
.qatools a {
  display: inline !important;
  padding: 5px;
  line-height: 25px;
}

#C5State {
  overflow-wrap: anywhere;
  text-align: left;
  display: none;
}

.tablesfigurestables {
  display: block;
}

.tablesfiguresfigures {
  display: block;
}

.tablesfiguresxref {
  display: block;
}

.showtandfsection {
  display: block;
}

.hidetandfsection {
  display: none;
}

#TOC_ID .tnfxref ditatitle {
  word-wrap: break-word;
  width: 170px;
}

sli {
  display: block;
}

sl {
  margin: 16px;
  display: block;
}

refbody xref[href^="https://pubmed.ncbi"], refbody xref[href^="https://www.ncbi.nlm.nih.gov/pubmed"] {
  color: transparent;
  display: inline-block;
  width: 60px;
  background-image: url("../assets/images/pubmed.gif");
  background-repeat: no-repeat;
  height: 19px;
}

contentId .etgtopic h2 {
  margin-top: 20px;
}

conbody table ph[outputclass=pull-right] {
  float: left !important;
  margin: 5px !important;
}
conbody table ph[outputclass=pull-right] xref, conbody table ph[outputclass=pull-right] term {
  margin: 0px 8px 0px 0px !important;
}
conbody table note ul {
  font-size: 12px !important;
}
conbody fig ditatitle {
  padding: 2px 4px 6px !important;
}
conbody fig p {
  padding-left: 5px !important;
  padding-right: 5px !important;
}
conbody fig note {
  min-width: -webkit-fill-available;
  max-width: -moz-fit-content !important;
  max-width: fit-content !important;
  position: relative !important;
  bottom: 8px !important;
  margin-left: 5px !important;
  margin-right: 5px !important;
  margin: 0;
}

table thead {
  background: #D9F1F3;
}
table thead th:nth-child(1) {
  width: 20%;
}
table thead th:nth-child(2) {
  width: 25%;
}
table thead th:nth-child(3) {
  width: 50%;
}
table a {
  color: #000;
  text-decoration: underline;
}
table note {
  font-size: 12px;
  line-height: 22px;
  background: #e6e6e6;
  padding: 3px 6px;
  width: 100%;
  display: block;
  border-top: 3px solid #FFF;
  margin-top: -6px;
  margin-bottom: 0px;
}
table > colgroup > col {
  width: 10% !important;
}

fig {
  border: solid 1px;
  margin: 0 0 15px 0;
  padding: 5px;
  display: inline-block;
}
fig note {
  font-size: 12px;
  line-height: 22px;
  background: #e6e6e6;
  padding: 3px 6px;
  width: 100%;
  display: block;
  border-top: 3px solid #FFF;
  margin-top: -6px;
  margin-bottom: 0px;
}
fig[outputclass=hw_boxed] {
  border: solid 1px;
  margin: 0 0 15px 0;
  padding: 5px;
  display: inline-block;
}

.savedFav {
  background: url(https://betatgldcdp.tg.org.au/svgassets/tg_favouritestar_filled.svg), linear-gradient(transparent, transparent) !important;
}

.savedfav, .savedTopic {
  background: url(../assets/images/tg_favouritestar_filled.png), linear-gradient(transparent, transparent) !important;
}

.guideline-box related-links {
  display: block !important;
}

related-links link {
  display: block !important;
}

p > xref {
  color: #036;
}
p.formstrength {
  font-family: "FranklinGothicITCbyBT-Book", sans-serif;
  font-size: 8pt;
}
p.pbsdisclaimer {
  font-family: "FranklinGothicITCbyBT-Book", sans-serif;
  font-size: 8pt;
  font-weight: normal;
  text-decoration: none;
  margin-bottom: 0pt;
  margin-top: 0cm;
}
p.eppbstextbold, p.eppbstext {
  font-family: "FranklinGothicITCbyBT-Book", sans-serif;
  font-size: 8pt;
  color: black;
  text-decoration: none;
  margin-bottom: 0pt;
  margin-top: 0pt;
}
p.pbsh1 {
  margin-bottom: 0pt;
  margin-top: 0pt;
  margin-left: 0cm;
  margin-right: 0cm;
  text-indent: 0cm;
  font-family: "FranklinGothicITCbyBT-Book", sans-serif;
  font-size: 8pt;
  font-style: normal;
  color: black;
}
p.pbsh3 {
  margin-bottom: 0pt;
  margin-top: 6pt;
  font-family: "FranklinGothicITCbyBT-Book", sans-serif;
  font-size: 8pt;
  color: black;
  margin-left: 10pt;
  font-style: normal;
}
p.pbsindication {
  margin-bottom: 0pt;
  margin-top: 6pt;
  font-family: "FranklinGothicITCbyBT-Book", sans-serif;
  font-size: 8pt;
  color: black;
  margin-left: 10pt;
  font-style: normal;
}
p.pbsh4 {
  margin-bottom: 0pt;
  margin-top: 6pt;
  font-family: "FranklinGothicITCbyBT-Book", sans-serif;
  font-size: 8pt;
  color: #000000;
  background-color: lightgrey;
  padding-left: 6pt;
  margin-left: 10pt;
}

.book-addiction {
  background: #004851 url(/public/viewTopic/ADG.png) no-repeat top right;
}
.book-addiction-gl {
  border: 1px solid #004851;
}
.book-antibiotic {
  background: #F7AEAD url(/public/viewTopic/anti.png) no-repeat top right;
}
.book-antibiotic-gl {
  border: 1px solid #F7AEAD;
}
.book-bone {
  background: #bcbf00 url(/public/viewTopic/bone.png) no-repeat top right;
}
.book-bone-gl {
  border: 1px solid #bcbf00;
}
.book-cardiovascular {
  background: #9B7DB9 url(/public/viewTopic/card.png) no-repeat top right;
}
.book-cardiovascular-gl {
  border: 1px solid #9B7DB9;
}
.book-dermatology {
  background: #958771 url(/public/viewTopic/derm.png) no-repeat top right;
}
.book-dermatology-gl {
  border: 1px solid #958771;
}
.book-developmental {
  background: #3BC4B7 url(/public/viewTopic/ddg.png) no-repeat top right;
}
.book-developmental-gl {
  border: 1px solid #3BC4B7;
}
.book-diabetes {
  background: #FFF452 url(/public/viewTopic/end.png) no-repeat top right;
}
.book-diabetes-gl {
  border: 1px solid #FFF452;
}
.book-diabetes #topic-banner {
  color: #414042 !important;
}
.book-fatigue {
  background: #424143 url(/public/viewTopic/fatigue.png) no-repeat top right;
}
.book-fatigue-gl {
  border: 1px solid #424143;
}
.book-gastrointestinal {
  background: #00549F url(/public/viewTopic/gastr.png) no-repeat top right;
}
.book-gastrointestinal-gl {
  border: 1px solid #00549F;
}
.book-liver {
  background: #470a68 url(/public/viewTopic/liver.png) no-repeat top right;
}
.book-liver-gl {
  border: 1px solid #470a68;
}
.book-neurology {
  background: #EC0928 url(/public/viewTopic/neuro.png) no-repeat top right;
}
.book-neurology-gl {
  border: 1px solid #EC0928;
}
.book-oral {
  background: #8B83D7 url(/public/viewTopic/oral.png) no-repeat top right;
}
.book-oral-gl {
  border: 1px solid #8B83D7;
}
.book-other {
  background: #dddedf;
}
.book-other-gl {
  border: 1px solid #dddedf;
}
.book-pain {
  background: #00a4e4 url(/public/viewTopic/anal.png) no-repeat top right;
}
.book-pain-gl {
  border: 1px solid #00a4e4;
}
.book-palliative {
  background: #BAE0CA url(/public/viewTopic/pallia.png) no-repeat top right;
}
.book-palliative-gl {
  border: 1px solid #BAE0CA;
}
.book-psychotropic {
  background: #00A261 url(/public/viewTopic/psych.png) no-repeat top right;
}
.book-psychotropic-gl {
  border: 1px solid #00A261;
}
.book-respiratory {
  background: #fdbf57 url(/public/viewTopic/respir.png) no-repeat top right;
}
.book-respiratory-gl {
  border: 1px solid #fdbf57;
}
.book-rheumatology {
  background: #F26922 url(/public/viewTopic/rheu.png) no-repeat top right;
}
.book-rheumatology-gl {
  border: 1px solid #F26922;
}
.book-sexual {
  background: #9d0054 url(/public/viewTopic/sexual.png) no-repeat top right;
}
.book-sexual-gl {
  border: 1px solid #9d0054;
}
.book-toxicology {
  background: #425563 url(/public/viewTopic/toxicology.png) no-repeat top right;
}
.book-toxicology-gl {
  border: 1px solid #425563;
}
.book-ulcer {
  background: #89D4DF url(/public/viewTopic/ulcer.png) no-repeat top right;
}
.book-ulcer-gl {
  border: 1px solid #89D4DF;
}
.book-wilderness {
  background: #BCBDC0 url(/public/viewTopic/wild.png) no-repeat top right;
}
.book-wilderness-gl {
  border: 1px solid #BCBDC0;
}
.book-wilderness #topic-banner {
  color: #414042 !important;
}

.bodyContentDiv h5 {
  font-weight: 700 !important;
  font-family: FranklinGothicITCbyBT-Demi, sans-serif !important;
}

index, index-div, index-entry, term, indication {
  display: none;
}

.topicontainer {
  cursor: pointer;
}

#tocBox .section-heading {
  margin: 0;
}
#tocBox a {
  width: auto;
  height: auto;
  text-decoration: none;
  background-color: #fff;
  text-decoration: none;
  font-weight: bold;
  font-size: 13px;
  color: #222;
  padding: 5px 5px 5px 8px;
  display: block;
  cursor: pointer;
}
#tocBox ul {
  background: #fff;
  margin: 0;
  padding: 0;
  padding: 0px;
  margin: 0px;
  list-style-type: none;
}
#tocBox ul > li {
  display: list-item;
}
#tocBox ul li {
  margin-left: 0;
  padding: 0px;
  margin: 0px;
  list-style-type: none;
}
#tocBox ul li a.tocImgCls {
  display: block;
  float: left;
  width: 5%;
  padding-right: 12px;
}
#tocBox ul li ul li, #tocBox ul li ul li ul li {
  margin-left: 13px;
}
#tocBox li {
  display: inline;
}
#tocBox li img {
  padding-right: 5px;
}
#tocBox li a {
  margin-left: 5px;
  padding: 2px;
  margin-bottom: 2px;
  text-decoration: none;
  width: 100%;
  height: 100%;
  color: #000;
  font-weight: bold;
  font-family: "FranklinGothicITCbyBT-Book", sans-serif;
  font-size: 12px;
  display: block;
}
#tocBox li a:hover {
  background-color: #E7ECFA;
  color: #404102;
  text-decoration: none;
}
#tocBox li > a {
  width: auto;
  height: auto;
  text-decoration: none;
  background-color: #fff;
  text-decoration: none;
  font-weight: bold;
  font-size: 13px;
  color: #222;
  padding: 3px 3px 3px 5px;
  display: block;
  cursor: pointer;
}
#tocBox li li a {
  font-size: 12px;
}
#tocBox li li li a {
  font-size: 11px;
}

.reviseddate {
  margin-top: 30px;
}

.fnCls > div {
  display: inline !important;
}
.fnCls > span.online {
  display: none !important;
}

fn[fn-type=table-fn] {
  background: #e6e6e6;
  font-size: 12px;
  padding: 6px 6px 3px 6px;
}
fn[fn-type=table-fn] div div {
  display: inline !important;
}
fn:not([fn-type=table-fn]) {
  margin-bottom: 20px !important;
  padding: 6px;
}
fn:not([fn-type=margin-note]) {
  display: block;
  background: #e6e6e6;
  font-family: "FranklinGothicITCbyBT-Book", Helvetica, sans-serif;
}

attrib {
  background: #e6e6e6;
  display: block;
}

table-wrap fn[fn-type=table-fn] {
  padding: 0 !important;
}

.multipleDrugs {
  background: #e0e0e0;
  padding: 8px;
  position: absolute;
  z-index: 999;
  margin-top: -29px !important;
  right: 150px;
}

.nomargin {
  margin: 0;
}

.icon-wrapper {
  position: relative;
  display: block;
}

.figure {
  display: block;
  max-width: 100%;
  height: auto;
}

.topic-tools {
  background: #d3d4d5;
  width: 100%;
  padding: 5px 0 1px 0;
}
.topic-tools ul {
  margin: 0;
}
.topic-tools .topic-icons {
  float: none;
}
.topic-tools > li a.togglefontsize {
  display: inline-block;
  width: 34px;
  height: 34px;
  background-size: contain;
}

.etgtopic {
  font-size: 14px;
}
.etgtopic .listBullet {
  width: 25px;
  font-weight: 700;
  height: 25px;
  border-radius: 40px;
  font-size: 14px;
  color: #000;
  line-height: 24px;
  text-align: center;
  text-decoration: none;
  text-shadow: 0 1px 0 #fff;
  background: #fff;
  float: left;
  margin: 0 10px 10px 5px;
  font-family: "DINNeuzeitGrotesk-Light", sans-serif;
}
.etgtopic .newLine {
  display: block !important;
  padding-left: 0px !important;
}
.etgtopic figcaption {
  text-align: center;
  font-style: italic;
  margin: 10px;
  padding: 8px;
  font-size: 12px;
  line-height: 20px;
}
.etgtopic a {
  color: #003366;
  text-decoration: underline;
}
.etgtopic p {
  font-family: "FranklinGothicITCbyBT-Book", sans-serif;
  font-family: "Arial", Helvetica, sans-serif;
  font-size: 14px;
  line-height: 20px;
}
.etgtopic ul {
  font-family: "FranklinGothicITCbyBT-Book", sans-serif;
  font-family: "Arial", Helvetica, sans-serif;
  font-size: 14px;
  line-height: 20px;
  list-style-type: square;
}
.etgtopic ul li {
  line-height: 20px;
  margin-bottom: 0;
}
.etgtopic ul li list-item {
  width: 100%;
}
.etgtopic ul li list-item div {
  display: table-cell;
}
.etgtopic ul li list-item div:nth-child(2) {
  padding-left: 8px;
}
.etgtopic ul ul {
  list-style-type: disc;
}
.etgtopic ul ul ul {
  list-style-type: circle;
}
.etgtopic ul[style="list-style:none"] li {
  width: 100%;
}
.etgtopic ul[style="list-style:none"] li div {
  display: table-cell;
}
.etgtopic ul[style="list-style:none"] li div:nth-child(2) {
  padding-left: 8px;
}
.etgtopic .note-block {
  background: #eee;
  margin-top: 20px;
  padding: 10px 10px 2px 10px;
}
.etgtopic .note-block ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.etgtopic .note-block ul li {
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 10px;
}
.etgtopic .tgltablewrapper {
  background: #ecf6f7;
}
.etgtopic .tgltablewrapper h2 {
  background: none;
  line-height: normal;
}
.etgtopic .tgl-box {
  background: #D9F1F3;
  padding: 6px;
}
.etgtopic .tgl-box h2 {
  background: none;
  line-height: normal;
  margin-top: 0;
}
.etgtopic .tgl-box p {
  margin-bottom: 6px;
}
.etgtopic .alert {
  padding: 6px;
  margin-bottom: 20px;
  border: 1px solid transparent;
  border-radius: 4px;
}
.etgtopic .alert-info {
  background: #F9E0C3;
  color: #2f2f2f;
  font-size: 14px;
}
.etgtopic .alert-info i {
  font-size: 16px;
}
.etgtopic .tgltable {
  width: 100%;
  background: #ecf6f7;
  font-size: 15px;
  color: #181818;
}
.etgtopic .tgltable thead {
  background: #D9F1F3;
  color: #000;
  font-size: 16px;
  font-weight: 700;
  font-family: "FranklinGothicITCbyBT-Demi", sans-serif;
}
.etgtopic .tgltable thead th {
  padding: 6px 10px;
  white-space: normal;
}
.etgtopic .tgltable tfoot {
  background: #ecf1f2;
}
.etgtopic .tgltable tfoot p {
  font-size: 14px;
  margin-bottom: 10px;
  line-height: normal;
}
.etgtopic .tgltable td {
  border: 2px solid #fff;
  padding: 6px;
}
.etgtopic .tgltable .tglmtd {
  background: #cbe5e7;
  font-weight: 700;
}
.etgtopic .tglfigwrapper {
  background: #ecf6f7;
  padding: 20px 20px 10px 20px;
  margin: 0 auto;
}
.etgtopic .tglfigwrapper p {
  text-align: center;
  font-size: 15px;
  line-height: 21px;
  font-family: "FranklinGothicITCbyBT-Book", sans-serif;
}
.etgtopic .tglfigwrapper h2 {
  background: none;
  line-height: normal;
  padding-left: 10px;
}
.etgtopic .tglfigwrapper .headingTable {
  font-size: 21px;
  line-height: 27px;
  font-family: "FranklinGothicITCbyBT-Demi", sans-serif;
  padding: 5px;
}
.etgtopic .tglfigwrapper .tFigHeading {
  font-size: 14px;
  line-height: 26px;
  font-family: "FranklinGothicITCbyBT-Book", sans-serif;
  padding: 5px;
  font-weight: 600;
}
.etgtopic ol {
  font-family: "FranklinGothicITCbyBT-Book", sans-serif;
  font-family: "Arial", Helvetica, sans-serif;
  font-size: 14px;
  line-height: 20px;
}
.etgtopic ol li {
  line-height: 20px;
  margin-bottom: 0;
}
.etgtopic table p, .etgtopic table ul, .etgtopic table ol {
  font-family: "FranklinGothicITCbyBT-Book", sans-serif;
  font-family: "Arial", Helvetica, sans-serif;
  font-size: 14px;
  line-height: 20px;
}
.etgtopic table.pbs p, .etgtopic table.pbs ul, .etgtopic table.pbs ol {
  font-size: 12px;
}
.etgtopic back dt {
  font-weight: normal;
}
.etgtopic-banner-head, .etgtopic h1 {
  font-family: "DINNeuzeitGrotesk-Light", sans-serif;
  color: #fff;
  font-size: 48px;
  line-height: 54px;
  font-weight: 400;
  text-align: left;
}
.etgtopic h2 {
  font-size: 28px;
  border-bottom: 1px solid #444;
  padding-bottom: 10px;
  margin-top: 30px;
  line-height: 32px !important;
  font-family: "FranklinGothicITCbyBT-Demi", sans-serif;
}
.etgtopic h2 span {
  padding-left: 0px;
}
.etgtopic h3 {
  font-size: 24px;
  color: #333333;
  padding: 0;
  margin-top: 0;
  line-height: 28px;
  margin-bottom: 10px;
  font-family: "FranklinGothicITCbyBT-Demi", sans-serif;
}
.etgtopic h3.popover-title {
  font-size: 14px !important;
  padding: 0px 14px !important;
}
.etgtopic h4 {
  font-size: 20px;
  color: #333333;
  font-weight: 700;
  line-height: 24px;
  margin-bottom: 8px;
}
.etgtopic h5 {
  font-size: 16px;
  color: #333333;
  font-weight: 700;
  padding-bottom: 8px;
  margin-bottom: 1px;
  line-height: 20px;
  font-family: "FranklinGothicITCbyBT-Demi", sans-serif;
}
.etgtopic h6 {
  font-size: 14px;
  color: #333333;
  font-weight: 700;
  line-height: 18px;
  margin-top: 1px;
  margin-bottom: 10px;
}
.etgtopic p {
  margin-bottom: 10px;
}
.etgtopic .disp-quote {
  border: none;
  padding: 10px 5px;
  color: #414042;
  background: #d9f1f3;
  font-family: inherit;
  margin-bottom: 10px;
}
.etgtopic .disp-quote svg {
  width: 25px;
  height: 25px;
}
.etgtopic .disp-quote div {
  margin: 8px 0px;
}
.etgtopic .disp-quote div.pbs p, .etgtopic .disp-quote div.preg p, .etgtopic .disp-quote div.bf p {
  font-size: 12px;
}
.etgtopic .disp-quote p {
  font-size: 14px;
  line-height: 20px;
}
.etgtopic .disp-quote p.margin9prct {
  margin-left: 117px !important;
}
.etgtopic .disp-quote .margin9prct {
  margin-left: 75px;
}
.etgtopic .disp-quote .margin30prct {
  margin-left: 30%;
}
.etgtopic ref-list {
  font-size: 14px;
  line-height: 22px;
  background: #e6e6e6;
  padding: 3px 6px;
  width: 100%;
  display: block;
  margin-bottom: 6px;
}
.etgtopic ref-list .figure {
  display: inline-block;
}
.etgtopic ref-list h3 {
  margin-bottom: 6px;
}
.etgtopic .fnCls {
  font-size: 14px;
  line-height: 22px;
  background: #e6e6e6;
  padding: 3px 6px;
  width: 100%;
  display: block;
}
.etgtopic .dosage-block {
  border: none;
  font-size: 14px;
  margin-bottom: 10px;
  color: #414042;
  background: #d9f1f3;
  font-family: inherit;
  line-height: 21px;
}
.etgtopic .dosage-block td {
  border: none;
  padding: 10px;
  line-height: 20px;
}
.etgtopic .dosage-block td:nth-child(1) {
  width: 5%;
}
.etgtopic .dosage-block td:nth-child(3) {
  width: 18%;
}
.etgtopic .dosage-block a.pbs,
.etgtopic .dosage-block a.pregnancy {
  margin-right: 8px;
}
.etgtopic .dosage-block2 td:nth-child(1) {
  width: 5%;
}
.etgtopic .dosage-block2 td:nth-child(3) {
  width: 18%;
}
.etgtopic .dosage-icons {
  list-style: none;
}
.etgtopic .dosage-icons > li > a {
  display: inline-block;
  padding-right: 2px;
  width: 27px;
  height: 27px;
}
.etgtopic .dosage-icons > li {
  display: inline-block;
  padding-right: 2px;
  width: 27px;
  height: 27px;
}
.etgtopic .dosage-icons > li a .pbs {
  position: relative;
  float: left;
  width: 27px;
  height: 27px;
  box-shadow: 0px 1px 6px #33585C;
  border-radius: 190px;
  background: url(../assets/images/tg_dosage_pbs.png) no-repeat;
  background: url(../assets/images/tg_dosage_pbs.svg), linear-gradient(transparent, transparent);
}
.etgtopic .dosage-icons > li a .pbs:hover {
  background: url(../assets/images/tg_dosage_pbs_hover.png) no-repeat;
  background: url(../assets/images/tg_dosage_pbs_hover.svg), linear-gradient(transparent, transparent);
}
.etgtopic .dosage-icons > li a .pregnancy {
  background: url(../assets/images/tg_dosage_pregnancy.png) no-repeat;
  background: url(../assets/images/tg_dosage_pregnancy.svg), linear-gradient(transparent, transparent);
}
.etgtopic .dosage-icons > li a .pregnancy:hover {
  background: url(../assets/images/tg_dosage_pregnancy_hover.png) no-repeat;
  background: url(../assets/images/tg_dosage_pregnancy_hover.svg), linear-gradient(transparent, transparent);
}
.etgtopic .dosage-icons > li a .breast_feeding {
  background: url(../assets/images/tg_dosage_breastfeeding.png) no-repeat;
  background: url(../assets/images/tg_dosage_breastfeeding.svg), linear-gradient(transparent, transparent);
}
.etgtopic .dosage-icons > li a .breast_feeding:hover {
  background: url(../assets/images/tg_dosage_breastfeeding_hover.png) no-repeat;
  background: url(../assets/images/tg_dosage_breastfeeding_hover.svg), linear-gradient(transparent, transparent);
}
.etgtopic .pull-left {
  float: left;
}
.etgtopic .pull-right {
  float: right;
}
.etgtopic table-wrap p#toc_t00000, .etgtopic table-wrap p#toc_t00001 {
  font-size: 14px;
  font-weight: 700;
  margin-top: 20px;
}
.etgtopic .table tr > th {
  border: 2px solid #fff;
}
.etgtopic .table tr > th > div > p {
  margin-bottom: 0px !important;
}
.etgtopic .table tbody tr td {
  vertical-align: top;
}
.etgtopic .imp {
  font-weight: 700;
}
.etgtopic tr td div ul {
  padding-left: 20px !important;
}
.etgtopic figure {
  text-align: center;
  margin-top: 20px;
}
.etgtopic figure img.img-responsive {
  display: inline-block;
}
.etgtopic .figure img {
  border: 1px solid #000;
}
.etgtopic td div p {
  margin-bottom: 10px;
}

.fn-group {
  font-size: 14px;
  line-height: 22px;
  background: #e6e6e6;
  padding: 3px 6px;
  width: 100%;
  display: block;
}
.fn-group fn p {
  margin-bottom: 3px;
}
.fn-group fn p span {
  font-size: 12px;
  line-height: 16px;
}
.fn-group fn ul li {
  font-size: 12px;
  line-height: 16px;
}
.fn-group fn ol li {
  font-size: 12px;
  line-height: 16px;
}

back {
  font-family: "FranklinGothicITCbyBT-Book", sans-serif;
  font-family: "Arial", Helvetica, sans-serif;
  font-size: 14px;
  line-height: 20px;
}

.section-ref {
  background: #e6e6e6;
  padding: 3px 6px;
  display: block;
  margin-top: 10px;
  margin-bottom: 40px;
}

.topic-icons {
  list-style: none;
  display: inline-block;
  padding: 0;
  margin: 0;
  float: right;
}
.topic-icons li {
  display: inline-block;
  padding-right: 2px;
}
.topic-icons > li a.favourite, .topic-icons > li a.topicFavourite {
  display: inline-block;
  width: 34px;
  height: 34px;
  background: url(../assets/images/tg_favouritestar_empty.png) no-repeat;
  background: url(../assets/images/tg_favouritestar_empty.svg), linear-gradient(transparent, transparent);
  cursor: pointer;
}
.topic-icons > li a.favourite:hover, .topic-icons > li a.favourite.active, .topic-icons > li a.topicFavourite:hover, .topic-icons > li a.topicFavourite.active {
  background: url(../assets/images/tg_favouritestar_filled.png) no-repeat;
  background: url(../assets/images/tg_favouritestar_filled.svg), linear-gradient(transparent, transparent);
}
.topic-icons > li a.favourite.disabled, .topic-icons > li a.topicFavourite.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
.topic-icons > li a.print {
  display: inline-block;
  width: 34px;
  height: 34px;
  background: url(../assets/images/tg_print_empty.png) no-repeat;
  background: url(../assets/images/tg_print_empty.svg), linear-gradient(transparent, transparent);
}

.epsubheadlinetgl {
  font-size: 18px;
  line-height: 22px;
  font-weight: 700;
  margin-bottom: 5px;
}

.navlinks {
  background: #e6e6e6;
  padding: 10px;
  font-size: 12px;
  margin-bottom: 20px;
}
.navlinks td {
  padding: 10px;
}

.notetbl,
.fnotetable {
  background: #e6e6e6;
  font-size: 12px;
}
.notetbl td,
.fnotetable td {
  padding: 10px;
}

.epnb {
  margin: 0;
}

.li2_1 {
  margin-bottom: 15px;
}

.citationbody {
  margin-top: 20px;
  font-size: 12px;
}

.epseeninrelated {
  font-weight: 700;
  margin: 0;
}

.eptopictopics {
  margin: 0;
  font-size: 12px;
}

.topic-icons > li a.print {
  display: inline-block;
  width: 34px;
  height: 34px;
  background: url(../assets/images/tg_print_empty.png) no-repeat;
  background: url(../assets/images/tg_print_empty.svg), linear-gradient(transparent, transparent);
}
.topic-icons > li a.print:hover {
  background: url(../assets/images/tg_print_filled.png) no-repeat;
  background: url(../assets/images/tg_print_filled.svg), linear-gradient(transparent, transparent);
}
.topic-icons > li a.feedback, .topic-icons > li a.topicFeedback {
  display: inline-block;
  width: 34px;
  height: 34px;
  background: url(../assets/images/tg_feedback_empty.png) no-repeat;
  background: url(../assets/images/tg_feedback_empty.svg), linear-gradient(transparent, transparent);
}
.topic-icons > li a.feedback:hover, .topic-icons > li a.topicFeedback:hover {
  background: url(../assets/images/tg_feedback_filled.png) no-repeat;
  background: url(../assets/images/tg_feedback_filled.svg), linear-gradient(transparent, transparent);
}
.topic-icons > li a.expertgroup {
  display: inline-block;
  width: 34px;
  height: 34px;
  background: url(../assets/images/tg_expertgroup_empty.png) no-repeat;
  background: url(../assets/images/tg_expertgroup_empty.svg), linear-gradient(transparent, transparent);
}
.topic-icons > li a.expertgroup:hover {
  background: url(../assets/images/tg_expertgroup_filled.png) no-repeat;
  background: url(../assets/images/tg_expertgroup_filled.svg), linear-gradient(transparent, transparent);
}
.topic-icons > li a.endorsement {
  display: inline-block;
  width: 34px;
  height: 34px;
  background: url(../assets/images/tg_endorsement_empty.png) no-repeat;
  background: url(../assets/images/tg_endorsement_empty.svg), linear-gradient(transparent, transparent);
}
.topic-icons > li a.endorsement:hover {
  background: url(../assets/images/tg_endorsement_filled.png) no-repeat;
  background: url(../assets/images/tg_endorsement_filled.svg), linear-gradient(transparent, transparent);
}

.disp-quote .drugIcons a {
  width: 32px;
  margin-left: 8px;
  height: 30px;
}
.disp-quote list ul[style*="margin-left: 5%"] > li > list-item > table > tbody > tr > td.drugTextTD {
  width: 67% !important;
}
.disp-quote list ul > li list-item {
  padding-left: 31px;
}
.disp-quote list ul > li list-item > table > tbody > tr > td .drugTextTD {
  width: 71% !important;
}
.disp-quote list ul > li list-item > table > tbody > tr > td .drugWORanking {
  padding-left: 42px;
}
.disp-quote list ul > li list-item > div.drugJoiningWord {
  padding: 0 0 0 45px !important;
}
.disp-quote .drugTextTD {
  position: relative;
  width: 80%;
  vertical-align: top;
}
.disp-quote .drugIcons {
  vertical-align: top;
  text-align: right;
  width: 15%;
  padding-right: 5px;
}
.disp-quote table {
  width: 100%;
}
.disp-quote .drugJoiningWord {
  position: relative;
  padding: 0 0 0 42px;
  width: 100%;
}
.disp-quote span.icons {
  padding-top: 10px;
  display: table;
  width: 100%;
  position: relative;
  float: none;
  text-align: right;
}
.disp-quote .drugRanking {
  padding: 0 10px 10px 5px !important;
  width: 5%;
  vertical-align: top;
}
.disp-quote .drugWORanking {
  width: 5%;
}
.disp-quote .rankingBullet {
  width: 25px;
  font-weight: 700;
  height: 25px;
  border-radius: 40px;
  font-size: 14px;
  color: #000;
  line-height: 24px;
  text-align: center;
  text-decoration: none;
  text-shadow: 0 1px 0 #fff;
  background: #fff;
  float: left;
  margin: 0 10px 10px 5px;
  font-family: "DINNeuzeitGrotesk-Light", sans-serif;
}

.tooltip {
  text-align: left;
  color: #fff;
  font-size: 12px;
  background: #111;
  position: absolute;
  padding: 6px;
  z-index: 999 !important;
}
.tooltip:after {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #111;
  content: "";
  position: absolute;
  left: 50%;
  bottom: -10px;
  margin-left: -10px;
}
.tooltip.top:after {
  border-top-color: transparent;
  border-bottom: 10px solid #111;
  top: -20px;
  bottom: auto;
}
.tooltip.left:after {
  left: 10px;
  margin: 0;
}
.tooltip.right:after {
  right: 10px;
  left: auto;
  margin: 0;
}

figure-title {
  font-size: 14px;
  font-weight: 700;
}

.etgtopic2 {
  font-size: 20px;
  background: #d7d7d7;
  color: #141414;
  padding: 6px 10px;
  line-height: 30px;
}

.etgtopic3 {
  font-size: 18px;
  color: #222222;
  font-weight: 700;
  line-height: 24px;
}

.margin-bottom8px {
  margin-bottom: 8px;
}

.paddingTop7px {
  padding-top: 7px;
}

.greyboxTopic {
  padding: 10px;
  background: #f1f1f1;
  margin: 10px 0;
  clear: both;
  display: table;
}

.modal__body {
  background: #ecf6f7;
}

.paddingLeft10px {
  padding-left: 10px !important;
}

.tblHeading {
  background: #ecf6f7;
  font-size: 15px;
  padding: 10px;
  font-weight: 600;
}

.marginTop-13px {
  margin-top: -13px !important;
}

.tgltablewrapper h2:after {
  border: none !important;
}

ul.referenceList {
  list-style: decimal !important;
  color: #000;
  padding-left: 18px;
}
ul.referenceList li {
  margin-bottom: 5px;
  color: #666;
  font-size: 12px;
}
ul.referenceList li a {
  text-decoration: none;
  margin-right: 5px;
}
ul.referenceList li a:hover {
  text-decoration: underline;
}
ul.breadcrumb {
  margin-left: -3px !important;
}
ul.rel-links > li {
  text-decoration: underline;
}

.ic_list {
  list-style: none;
  margin-top: 70px;
  padding-left: 0px;
}
.ic_list li {
  text-align: center;
  display: block;
  margin: 10px 30px 25px 30px !important;
  width: 100% !important;
}
.ic_list li a {
  background: #fff;
  text-decoration: none;
  border-radius: 4px;
  color: #000;
  padding: 5px 10px;
  width: 82% !important;
  float: left;
  margin-bottom: 10px !important;
}
.ic_list li a:hover {
  color: #fff;
  background: #666;
}

.referenceList li p {
  margin-bottom: 8px !important;
}

.referenceFont {
  color: #26609A;
  font-size: 14px;
  font-weight: 800;
}

.clrBlack {
  color: #000;
}

.font11 {
  font-size: 11px !important;
}

.figureText {
  background: #d5e7e9;
  padding: 10px;
}

.whiteBG {
  background: #fff;
  padding: 5px 3px;
}

h1 span {
  padding-left: 0px !important;
}

textarea {
  resize: vertical;
}

.popover {
  left: -14px !important;
  max-width: 372px !important;
  top: 34px;
  border: 1px solid rgba(0, 0, 0, 0.2) !important;
  border-radius: 6px;
  height: 160px !important;
}
.popover-content {
  padding: 15px !important;
  width: auto;
  min-width: 327px;
  height: 27px !important;
  background-color: #f7f7f7 !important;
  margin-bottom: 5px;
}
.popover-content .form-horizontal .form-group .form-control {
  height: 27px !important;
  background-color: #f7f7f7 !important;
  margin-bottom: 5px;
}
.popover-content .form-horizontal .form-group textarea.form-control {
  height: 90px !important;
  margin-bottom: 5px;
}
.popover .form-control {
  border: 1px solid #404040 !important;
}
.popover.bottom > .arrow {
  margin-left: -6px !important;
}

.margin-note {
  padding: 6px;
  margin-bottom: 20px;
  border: 1px solid transparent;
  border-radius: 4px;
}
.margin-note-info {
  background: #F5E0DB;
  color: #2f2f2f;
  font-size: 14px;
}

.divTextbox {
  border: solid 1px;
  margin: 0 0 15px 0;
  padding: 5px;
  background: #ecf6f7;
  display: inline-block;
}

.pbsfoot {
  border: 1px solid lightgrey;
  margin-top: 0px;
  margin-left: 0px;
  margin-right: 0px;
  margin-bottom: 0px;
  text-indent: 0cm;
  font-family: "FranklinGothicITCbyBT-Book", sans-serif;
  font-size: 60%;
  color: #000000;
  font-style: normal;
  font-weight: normal;
}

.innerDB,
.innerEP,
.innerGE {
  background-color: #ffffe7;
}

.innerHS,
.innerCT,
.innerIN,
.innerIP,
.innerHB,
.innerTY,
.innerTZ,
.innerGH,
.innerIF,
.innerMD,
.innerMF {
  background-color: #ffdcb9;
}

.innerPL {
  background-color: #eceeff;
}

.innerPQ,
.innerR1 {
  background-color: #ded4c3;
}

.alright {
  text-align: right;
}

.indication {
  margin-left: 10pt;
}

.bold {
  font-weight: bold;
}

.pbsnote {
  font-family: "FranklinGothicITCbyBT-Book", sans-serif;
  font-size: 8pt;
  color: black;
  margin-left: 18pt;
  margin-bottom: 0pt;
  margin-top: 0cm;
}

.pbscaution {
  font-family: "FranklinGothicITCbyBT-Book", sans-serif;
  font-size: 8pt;
  text-decoration: none;
  margin-left: 18pt;
  margin-bottom: 0pt;
  margin-top: 6pt;
}

.pbs,
.preg,
.bf {
  background-color: white;
  width: 100%;
  padding: 10px;
}

.pbsbtn {
  cursor: pointer;
}

.pbsexpand {
  cursor: pointer;
  /* background-image: url(scripts/expand.png); */
  background-repeat: no-repeat;
  background-position: center;
}

.pbsclose {
  cursor: pointer;
  /* background-image: url(scripts/close.png); */
  background-repeat: no-repeat;
  background-position: center;
  width: 60px;
}

.pDB,
.pEP,
.pGE {
  background-color: lemonchiffon;
  font-family: "FranklinGothicITCbyBT-Book", sans-serif;
  font-size: 9pt;
  font-weight: bold;
  padding: 5pt;
}

.pGH,
.pCT,
.pIN,
.pIP,
.pHB,
.pTY,
.pTZ,
.pHS,
.pMD,
.pMF,
.pIF {
  background-color: #FFB66C;
  font-family: "FranklinGothicITCbyBT-Book", sans-serif;
  font-size: 9pt;
  font-weight: bold;
  padding: 5pt;
}

.pPL {
  background-color: #B9BFFF;
  font-family: "FranklinGothicITCbyBT-Book", sans-serif;
  font-size: 9pt;
  font-weight: bold;
  padding: 5pt;
}

.pPQ,
.pR1 {
  background-color: khaki;
  font-family: "FranklinGothicITCbyBT-Book", sans-serif;
  font-size: 9pt;
  font-weight: bold;
  padding: 5pt;
}

.prescriberFilter {
  padding-left: 3px;
}
.prescriberFilter label {
  font-weight: bold;
  font-family: "FranklinGothicITCbyBT-Book", sans-serif;
  font-size: 8pt;
}

#tocHiddenPane {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background: #000;
  z-index: 1000;
  padding: 10px;
}

#tocCollapse #openLeft {
  position: absolute;
  top: 250px;
}

.fontWhite {
  color: #fff;
}

/*=======other=====*/
.searchwp {
  float: right;
  top: 7px;
  position: absolute;
  right: 30px;
  z-index: 2;
}

.topicSearch {
  height: 25px !important;
  margin: 3px 0px 7px 0px;
}

.openListItem { /* IE 9 */ /* Chrome, Safari, Opera */
  transform: rotate(37deg);
}

.activeTopic {
  background-color: #DEF6FF !important;
}
.activeTopic a {
  background-color: #DEF6FF !important;
}

.activeHash,
.subActiveTopic,
.correntItem {
  background-color: #DEF6FF !important;
}

.subTopicList {
  display: none;
}

.facet-group {
  display: flex;
  flex-wrap: wrap;
  border-top: 1px solid #f5f5f5;
}
.facet-group:hover {
  background-color: #f5f5f5;
}
.facet-item {
  display: inline;
  flex-grow: 1;
}
.facet-item-count {
  float: right;
}

.sticky {
  position: fixed;
  top: 0;
  z-index: 10000;
  box-shadow: 0 10px 10px -3px gray !important;
  background-color: #f1f1f2;
  left: 0px;
  width: 100%;
}

.footer-bottom ul {
  margin-left: -1.5px !important;
}

.showtandfsection h3, .showtandfsection h4 {
  font-family: "FranklinGothicITCbyBT-Demi", sans-serif;
  color: #414042;
}

conbody p, conbody ul {
  font-family: "FranklinGothicITCbyBT-Book", sans-serif;
  font-size: 14px;
  line-height: 20px;
  color: #414042;
}

topic p {
  font-family: "FranklinGothicITCbyBT-Book", sans-serif;
  font-size: 14px;
  line-height: 20px;
  color: #414042;
}

#allresultspreloader {
  margin-left: 42%;
}
#allresultspreloader img {
  width: 25px;
  margin: 18px;
}

.topic-item {
  text-decoration: underline;
}
.topic-item:hover {
  font-weight: bolder;
}

.drug-item a {
  text-decoration: underline;
}
.drug-item a:hover {
  font-weight: bolder;
}

.holder {
  margin: 15px 0;
  text-align: center !important;
}
.holder a {
  font-size: 12px;
  cursor: pointer;
  margin: 0 5px;
  color: #333;
}
.holder a:hover {
  background-color: #222;
  color: #fff;
}
.holder a.jp-previous {
  margin-right: 15px;
  /* margin-left: 40px;*/
  margin-top: -8px;
  background-color: #eee;
  padding: 5px 15px;
  font-size: 15px;
  float: left;
}
.holder a.jp-previous:hover {
  background-color: #444;
}
.holder a.jp-next {
  margin-right: 43px;
  margin-top: -11px;
  background-color: #eee;
  padding: 5px 15px;
  font-size: 15px;
  float: right;
}
.holder a.jp-next:hover {
  background-color: #444;
}
.holder a.jp-current, .holder a.jp-current:hover {
  padding: 2px 8px;
  font-weight: bold;
  text-decoration: none;
  background-color: #eee;
  border-radius: 30px;
  display: inline-block;
}
.holder a.jp-disabled, .holder a.jp-disabled:hover {
  color: #bbb;
}
.holder span {
  margin: 0 5px;
}

.holder1 {
  margin: 15px 0;
  text-align: center !important;
}
.holder1 a {
  font-size: 12px;
  cursor: pointer;
  margin: 0 5px;
  color: #333;
}
.holder1 a:hover {
  background-color: #222;
  color: #fff;
}
.holder1 a.jp-previous {
  margin-right: 15px;
  margin-top: -8px;
  background-color: #eee;
  padding: 5px 15px;
  font-size: 15px;
  float: left;
}
.holder1 a.jp-previous:hover {
  background-color: #444;
}
.holder1 a.jp-next {
  margin-right: 43px;
  margin-top: -11px;
  background-color: #eee;
  padding: 5px 15px;
  font-size: 15px;
  float: right;
}
.holder1 a.jp-next:hover {
  background-color: #444;
}
.holder1 a.jp-current, .holder1 a.jp-current:hover {
  padding: 2px 8px;
  font-weight: bold;
  text-decoration: none;
  background-color: #eee;
  border-radius: 30px;
  display: inline-block;
}
.holder1 a.jp-disabled, .holder1 a.jp-disabled:hover {
  color: #bbb;
}
.holder1 span {
  margin: 0 5px;
}

.hidden {
  display: none;
}

.btn-search:hover {
  text-decoration: none !important;
}
.btn-search.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

#add, #favcancel {
  font-family: "DINNeuzeitGrotesk-Light", sans-serif;
}

.savedfav,
.savedTopic {
  background: url(../assets/images/tg_favouritestar_filled.svg), linear-gradient(transparent, transparent) !important;
}

::-webkit-input-placeholder,
::-moz-placeholder,
::-ms-placeholder,
::placeholder {
  color: #6a686c !important;
}

#favhiddenIDName {
  color: #6a686c !important;
  cursor: default !important;
}
#searchString::-moz-placeholder {
  color: #414042 !important;
}
#searchString::-moz-placeholder, #searchString::-ms-placeholder, #searchString::placeholder {
  color: #414042 !important;
}

#etgcontent {
  padding-left: 4px !important;
  margin-left: 8px !important;
}
#etgcontent p {
  margin-bottom: 10px !important;
}

li.search-align-right {
  margin-left: auto !important;
}

#dart-nav ul.nav.navbar-nav.weblinks {
  max-width: 1180px !important;
  margin-left: auto;
  margin-right: auto;
}

#back-top {
  position: fixed;
  display: none;
  font-size: 40px;
  bottom: 13px;
  right: 20px;
  width: auto;
  outline: none;
  background: transparent !important;
  z-index: 999;
}

#toggle-font {
  position: fixed;
  display: none;
  font-size: 20px;
  /* color: rgba(0,0,0,0.5); */
  bottom: 13px;
  left: 24px;
  width: auto;
  outline: none;
  background: transparent !important;
  z-index: 999;
}

.page-header2 {
  padding: 10px;
}

#topic-banner {
  font-size: 48px;
}

.modal-title {
  margin: 0;
  line-height: 1.42857143;
  font-size: 18px;
  font-family: "FranklinGothicITCbyBT-Book", Helvetica, sans-serif;
  color: #414042;
}

.mobile-navbar {
  justify-content: space-between !important;
  display: flex !important;
}
.mobile-navbar th {
  padding: 5px 5px 5px 10px;
}
.mobile-logo {
  padding: 10px !important;
  margin-right: 20px;
}
.mobile-search {
  padding: 16px 10px;
}

.dropdown-menu {
  right: auto !important;
  position: absolute !important;
  border: 1px solid rgba(0, 0, 0, 0.15) !important;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.18) !important;
}

h2.topicontainer {
  display: flex !important;
  justify-content: space-between;
  gap: 10px;
}

.input-group-btn {
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.08);
}

.togglefontsize {
  background: url("../assets/images/increaseFontSize.png") no-repeat;
}
.togglefontsize:hover {
  filter: contrast(1.1);
}

#back-top:hover {
  filter: contrast(1.1);
}

.btmsticky {
  display: inline-block;
  width: 34px;
  height: 34px;
}

a.expertgroup {
  margin-right: -1px;
}

.popover-title {
  cursor: default !important;
}

.faveFocus {
  outline: -webkit-focus-ring-color auto 1px !important;
}

#favourite .btn {
  font-size: 13px;
}

.sortResults, .sortOptions {
  width: 100%;
}

mark {
  padding: 0px;
}

.homelink > svg {
  width: 170px;
  height: 60px;
}
.homelink > svg > img {
  width: 170px;
  height: 60px;
}

.row .sectionBlockLayout {
  text-align: left;
  min-height: 100px;
  margin: 0px -15px 15px;
}

.noLeftPadding {
  padding-left: 0;
}

.sectionBlockLayout {
  padding-top: 30px;
  padding-bottom: 8px;
}

.section-heading {
  color: rgb(65, 64, 66);
  margin-bottom: 20px;
  border-bottom: 1px solid rgb(65, 64, 66);
}
.section-heading h4 {
  font-family: DINNeuzeitGrotesk-Light, sans-serif;
  line-height: 24px;
}

.guideline-areas {
  margin-bottom: 10px;
  color: rgb(125, 125, 125);
  font-family: FranklinGothicITCbyBT-Book, Helvetica, sans-serif;
  font-size: 14px;
}
.guideline-areas > li {
  line-height: 28px !important;
}

.columnBlockLayout .search-container {
  margin-top: 10px;
}

.dotIcon {
  background: url(../assets/images/coverdots.png) no-repeat 0 0;
  width: 25px;
  height: 25px;
  float: left;
}
.dotIcon.addictionmedicine {
  background-position: 0px -620px;
}
.dotIcon.boneandmetabolism {
  background-position: 0px -499px;
}
.dotIcon.cardiovascular {
  background-position: 0 -24px;
}
.dotIcon.dermatology {
  background-position: 0 -51px;
}
.dotIcon.developmentaldisability {
  background-position: 0px -651px;
}
.dotIcon.diabetes {
  background-position: 1px -75px;
}
.dotIcon.fatigue {
  background-position: 0 -102px;
}
.dotIcon.gastrointestinal {
  background-position: 0 -130px;
}
.dotIcon.liverdisorders {
  background-position: 1px -563px;
}
.dotIcon.neurology {
  background-position: 0 -159px;
}
.dotIcon.oralanddental {
  background-position: 0 -183px;
}
.dotIcon.painandanalgesia {
  background-position: 1px -211px;
}
.dotIcon.palliativecare {
  background-position: 1px -242px;
}
.dotIcon.psychotropic {
  background-position: 1px -270px;
}
.dotIcon.respiratory {
  background-position: 0 -296px;
}
.dotIcon.rheumatology {
  background-position: -1px -323px;
}
.dotIcon.sexualandreproductivehealth {
  background-position: 0 -472px;
}
.dotIcon.toxicologyandtoxinology {
  background-position: 0 -531px;
}
.dotIcon.ulcerandwoundmanagement {
  background-position: -1px -349px;
}
.dotIcon.wildernessmedicine {
  background-position: 1px -380px;
}
.dotIcon.other {
  background-position: 0px -414px;
}

.goto-arrow {
  display: none;
}


.navbar-header {
  width: 100%;
}

#navbar-right-section {
  float: right;
  padding-top: 30px;
  position: absolute;
  right: 15px;
  top: 0px;
}
#navbar-right-section #subscribeBtn {
  background-color: green;
  border: 0;
}
#navbar-right-section #logInbtn {
  background-color: #414042;
  border: 0;
}
#navbar-right-section .btn-group {
  margin-right: 10px;
  min-width: 150px;
}
#navbar-right-section #myaccount-btn, #navbar-right-section #myaccount-btn-inst {
  padding: 7px;
  border: 0px solid #000000;
}
#navbar-right-section #myaccount-menu, #navbar-right-section #myaccount-menu-inst {
  background-color: #000000;
}
#navbar-right-section #myaccount-menu #subscribeBtn, #navbar-right-section #myaccount-menu #logInbtn, #navbar-right-section #myaccount-menu-inst #subscribeBtn, #navbar-right-section #myaccount-menu-inst #logInbtn {
  margin-top: -4px;
  padding: 5px;
}
#navbar-right-section #myaccount-menu #instlogout, #navbar-right-section #myaccount-menu-inst #instlogout {
  padding: 4px;
  background-color: #000000;
}

.white-jumbo {
  background-color: #fff;
  border: 1px solid #adadad;
  border-top: 2px solid #d8d8d8;
  border-left: 2px solid #d8d8d8;
  margin-top: 10px;
  padding: 20px;
}

/** calculator **/
.calculator-page {
  background: #1aa2a0 url(../assets/images/global_fold.png) no-repeat top right;
  padding: 10px 0px;
  margin: 10px 0 10px;
}

#topic-banner {
  font-size: 36px;
  font-family: "Segoe UI";
}

.nav-tabs {
  border-bottom: 0px !important;
}

.tabLeftContainer {
  z-index: 1 !important;
}

.tab-cal-container {
  padding-left: 0;
}

.tab-content > .tab-pane {
  display: block;
}

.tab-pane {
  padding: 0px 15px 7px !important;
  margin-bottom: 50px;
}
.tab-pane table {
  margin-bottom: 10px !important;
}

.tab-cal-content form {
  padding: 10px 30px 10px !important;
}
.tab-cal-content > .active {
  border-radius: 4px;
  border: 1px solid #61bfbf !important;
}

.tabs-left > li > a {
  border-radius: 4px !important;
}
.tabs-left > li.active > a {
  border-radius: 4px 0px 0px 4px;
}

.form-control[readonly] {
  background-color: #eee !important;
  opacity: 1;
}

.calculators {
  margin-top: 30px;
}

.btn-calc, .btn-clr {
  min-width: 96px !important;
  min-height: 35px;
  margin-left: 1px;
  margin-right: 16px;
  border-radius: 5px;
}

.nav-tabs > li {
  margin-bottom: 16px;
}

#bsacalc_formula {
  width: 275px;
  height: 37px;
}

#ccbwcalc_formula {
  width: 394px;
  height: 76px;
}

.ibwcalc_formula {
  width: 465px;
  height: 40px;
}

#lean_m {
  width: 355px;
  height: 45px;
}

#lean_f {
  width: 377px;
  height: 46px;
}

#bmi {
  width: 282px;
  height: 41px;
}

#gfr_formula {
  width: 353px;
  height: 42px;
}

/** Patient Info **/
.search-page {
  background: #1aa2a0 url(../assets/images/global_fold.png) no-repeat top right;
  padding: 10px 0px;
  margin: 10px 0 10px;
}

.page-header {
  width: 100%;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50%;
  margin-bottom: 20px !important;
}

#patientId {
  margin-bottom: 20px;
}
#patientId table {
  border: 1px solid #ddd;
  width: 100%;
  max-width: 100%;
  margin-bottom: 2px;
}
#patientId td {
  border: 1px solid #ddd;
  padding: 10px;
}
#patientId tbody tr:nth-child(odd) {
  background-color: #f9f9f9;
}

xref {
  cursor: pointer;
  color: #7d7d7d;
  text-decoration: underline;
}

ul #topics li:first-child, ul #topics li:nth-child(2) {
  display: none;
}

/** search */
#tgsearch .search-page {
  background: #1aa2a0 url(../assets/images/global_fold.png) no-repeat top right;
  padding: 10px 0px;
  margin: 10px 0 10px;
}
#tgsearch #topic-banner {
  font-size: 36px;
  font-family: "Segoe UI";
}
#tgsearch .loader {
  position: fixed;
  left: 0px;
  opacity: 0.85;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background: url(../assets/images/preloader.gif) 50% 50% no-repeat;
}
#tgsearch .recent-searches {
  padding-left: 0;
  list-style: none;
  color: #7d7d7d;
}
#tgsearch .recent-searches > li {
  line-height: 18px;
  font-size: 14px;
  word-wrap: break-word;
  margin-bottom: 12px;
}
#tgsearch .recent-searches > li > a {
  font-family: "FranklinGothicITCbyBT-Book", Helvetica, sans-serif;
  color: #7d7d7d;
  text-decoration: underline;
}
#tgsearch .removeSaveSearch {
  all: unset;
  cursor: pointer;
  font-size: 12px;
}
#tgsearch #topic-gl-container {
  min-height: 170px;
  margin-top: 10px;
}
#tgsearch #topic-gl-container > li {
  display: list-item;
  opacity: 1;
  margin-bottom: 0px !important;
}
#tgsearch #topic-gl-container > li > p {
  margin-bottom: 10px !important;
}
#tgsearch .active-gl {
  background-color: #8cffff;
}
#tgsearch .recent-searches {
  padding-left: 0;
  list-style: none;
  color: #7d7d7d;
}
#tgsearch .recent-searches > li {
  line-height: 18px;
  font-size: 14px;
  word-wrap: break-word;
  margin-bottom: 12px;
}
#tgsearch .recent-searches > li > a {
  font-family: "FranklinGothicITCbyBT-Book", Helvetica, sans-serif;
  color: #7d7d7d;
  text-decoration: underline;
}
#tgsearch .removeSaveSearch {
  all: unset;
  cursor: pointer;
  font-size: 12px;
}
#tgsearch #topic-gl-container li a:hover, #tgsearch #drug-indicator-container li a:hover {
  color: #23527c !important;
  text-decoration: underline;
}
#tgsearch .result-container a strong:hover {
  color: #23527c !important;
  text-decoration: underline;
}
#tgsearch #saveSearchBtn {
  color: #414042 !important;
}
#tgsearch .slider-arrow {
  display: none;
  left: 0px;
}
#tgsearch #facet-container {
  overflow-x: hidden !important;
}
#tgsearch .filter-desktop {
  display: block;
}
#tgsearch .filter-mobile {
  display: none;
}
#tgsearch #refineSearchbtn:hover {
  text-decoration: none !important;
  filter: contrast(1.1);
}

/** Media Queries **/
@media (min-width: 1200px) {
  .navbar-nav {
    max-width: 1200px !important;
    padding-right: 5px !important;
  }
  #searchForm {
    min-width: 190px !important;
  }
  .sectionBlockLayout {
    margin: auto -15px !important;
    padding-left: 3px !important;
    padding-right: 0px !important;
  }
  ul.breadcrumb {
    margin-left: 0px !important;
    margin-bottom: 0px !important;
  }
  .searchWithinPage {
    padding-right: 0px !important;
    margin-right: -8px !important;
  }
}
@media (max-width: 990px) {
  .mobilePanel {
    width: 300px;
    float: left;
    bottom: 0px;
    position: fixed;
    left: -290px;
    padding: 0;
    z-index: 99;
  }
  .slider-arrow {
    padding: 0 3px;
    width: 20px;
    float: left;
    background: #ccc;
    border-radius: 0 5px 5px 0;
    font-size: 25px;
    color: #000;
    text-decoration: none;
    position: absolute;
    margin-left: 100%;
    top: 219px;
  }
  .tgl_topic .hide {
    display: block !important;
  }
  .mobilePanel .minHeight {
    min-height: 350px;
  }
  .mobilePanel .greyboxTopic {
    margin-top: 0px;
  }
  .mobilePanel .slider-arrow {
    display: block !important;
  }
  .tree-menu {
    height: auto !important;
    overflow: auto;
  }
  #tgsearch .tree-menu {
    height: auto;
    overflow: auto;
  }
  #tgsearch #facet-container {
    height: 54vh;
  }
}
@media (max-width: 767px) {
  .tabs-left li.active {
    margin-right: 0px !important;
  }
  .tabs-left li.active > a {
    border-right-color: #61bfbf !important;
    border-radius: 4px 4px 4px 4px;
  }
  .tab-cal-container {
    padding-left: 1.2%;
    position: relative !important;
  }
  .ibwcalc_formula,
  .lbw_calcs,
  #gfr_formula {
    max-width: 100% !important;
    max-height: 49px !important;
    vertical-align: middle;
  }
  #tgsearch #topic-banner {
    text-align: center;
  }
  #tgsearch .slider-arrow {
    width: 20px !important;
    padding: 3px 0 0 !important;
  }
  #tgsearch .slider-arrow > i {
    width: 19px !important;
    font-size: 9px !important;
    padding: 10px 0;
  }
  #tgsearch .tree-menu {
    height: 100vh !important;
    max-height: 100%;
    margin-top: 0px !important;
    z-index: 1000 !important;
    position: absolute;
    top: 96px !important;
  }
  #tgsearch #facet-container {
    height: 88% !important;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  li .search-align-right {
    margin-left: auto !important;
  }
  #searchForm {
    min-width: 185px !important;
    max-width: 185px !important;
  }
  .sectionBlockLayout {
    margin: auto -15px !important;
    padding-left: 3px !important;
    padding-right: 0px !important;
  }
  ul.breadcrumb {
    margin-left: 0px !important;
    margin-bottom: 0px !important;
  }
  .searchWithinPage {
    padding-right: 0px !important;
    margin-right: -17px !important;
  }
  .tgnavigation #navbar {
    width: 955px !important;
  }
}
@media (min-width: 768px) {
  .col-sm-9 {
    padding-right: 0 !important;
    padding-left: 15px !important;
  }
  .desktop-navbar {
    display: block !important;
  }
  .topic-icons > li a.togglefontsize {
    display: none !important;
  }
  .searchMobileVisible,
  .btmsticky,
  .mobile-navbar {
    display: none !important;
  }
  div.tg-mobile-menu {
    display: block !important;
    width: 100%;
    background-color: #f8f8f8 !important;
    margin-top: 27px;
  }
  .tg-mobile-menu {
    width: 100%;
  }
}
@media (min-width: 481px) and (max-width: 1199px) {
  .dropdown-menu > li a {
    border: 2px solid rgba(0, 0, 0, 0);
  }
}
@media (min-width: 992px) {
  .slider-arrow {
    display: none !important;
  }
}
@media (max-width: 991px) {
  #tocId, #etgcontent {
    width: 100% !important;
  }
  #tocbox {
    position: fixed !important;
    z-index: 1025 !important;
    left: -335px;
    width: 335px;
    background-color: #ccc;
  }
  .slider-arrow {
    display: block !important;
    padding: 5px 0 0 !important;
    width: 25px !important;
    height: 40px !important;
    float: left !important;
    background: #ccc !important;
    color: #000 !important;
    text-decoration: none !important;
    position: fixed !important;
    margin-left: 0%;
    top: 20% !important;
    margin-top: 25vh !important;
    font-size: 15px !important;
    z-index: 1025;
  }
  .tree-menu {
    overflow: auto !important;
    margin-top: 20vh;
    top: 0px;
    height: auto !important;
    max-height: 50vh !important;
  }
  .quicklinks-pane {
    margin-top: 37vh !important;
  }
  #tg_toc_id {
    max-height: 50vh !important;
    overflow-y: auto !important;
    margin-top: -2px !important;
  }
  #tocId a {
    border-bottom: 2px solid #FFFFFF;
  }
  .show {
    border-radius: 0 5px 5px 0 !important;
  }
  .hide {
    border-radius: 5px 0 0 5px !important;
    transform: scaleX(-1);
    filter: FlipH;
    -ms-filter: "FlipH";
  }
  .flaticon-double30::before {
    font-size: 1em !important;
  }
  .searchContainer .slider-arrow {
    margin-top: 20vh !important;
    top: 96px !important;
  }
  #bsacalc_formula {
    width: 227px !important;
    height: 33px !important;
  }
  #ccbwcalc_formula {
    max-width: 100%;
    max-height: 100%;
    vertical-align: middle;
  }
  #tgsearch .slider-arrow {
    margin-top: 25vh !important;
    top: 20% !important;
    left: 0px;
    display: block !important;
    padding: 5px 0 0;
    width: 25px;
    height: 32px !important;
    float: left !important;
    background: #ccc !important;
    color: #000 !important;
    text-decoration: none !important;
    position: fixed !important;
    margin-left: 0%;
    top: 0px !important;
    margin-top: 20vh !important;
    font-size: 15px !important;
    z-index: 2000;
  }
  #tgsearch #sidemenu-container {
    position: fixed !important;
    z-index: 1025 !important;
    left: -290px;
    margin-left: 15px;
    width: 275px;
    background-color: #ccc;
  }
  #tgsearch .refine-container {
    padding: 10px;
    background: #f1f1f1;
    margin: 10px 0;
    clear: both;
    display: table;
  }
  #tgsearch .tree-menu {
    overflow: auto !important;
    margin-top: 20vh;
    top: 0px;
    max-height: 50vh;
  }
  #tgsearch #facet-container {
    background-color: #fff;
    overflow-y: auto !important;
  }
  #tgsearch #facet-container > h5 {
    padding: 5px 5px 5px 8px;
    margin: 0px 0px 0px 0px;
  }
  #tgsearch #facetList {
    margin: 10px;
  }
  #tgsearch #saveSearchBtn {
    margin-top: 5px;
  }
  #saveSearchBtn, #recentSearchDiv {
    margin: 10px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .sectionBlockLayout {
    margin: auto -15px !important;
    padding-left: 0px !important;
    padding-right: 20px !important;
  }
  ul.breadcrumb {
    margin-left: 0px !important;
    margin-bottom: 0px !important;
  }
  .searchWithinPage {
    padding-right: 0px !important;
    margin-right: -17px !important;
  }
  .ibwcalc_formula,
  .lbw_calcs,
  #gfr_formula {
    max-width: 100% !important;
    max-height: 70%;
    vertical-align: middle;
  }
}
@media (max-width: 768px) {
  #refineSearchbtn {
    display: inline-block !important;
    position: fixed;
    bottom: 13px;
    left: 20px;
    width: auto;
    outline: none;
    background-color: #f4f4f4;
    z-index: 999;
    font-size: 16px;
    cursor: pointer;
    padding: 7px;
    border-radius: 17px;
    font-family: "DINNeuzeitGrotesk-Light";
  }
  .holder a {
    font-size: 10px !important;
  }
  .holder a.jp-previous, .holder a.jp-next {
    padding: 5px !important;
    font-size: 10px !important;
    margin-top: -4px !important;
  }
  .desktop-navbar {
    display: none !important;
  }
  .page-heading {
    padding-top: 0px !important;
  }
  .page-heading ul.breadcrumb {
    display: none;
  }
  .page-header2 {
    background-image: none !important;
    padding-right: 10%;
  }
  .viewTopic-banner {
    margin-top: 0px !important;
  }
  #topic-banner {
    font-size: 36px !important;
  }
  .searchWithinPage {
    background-color: #ffffff !important;
    padding-left: 25px !important;
    padding-right: 15px !important;
    width: 100vw !important;
    margin-left: -15px !important;
    margin-right: -15px !important;
    float: left !important;
    padding-top: 10px !important;
    padding-bottom: 5px !important;
  }
  .searchWithinPage .form-control, .searchWithinPage .input-group-btn {
    background-color: #f4f4f4 !important;
    height: 50px !important;
    font-size: 20px !important;
    color: #555 !important;
    padding-left: 20px !important;
    padding-right: 10px !important;
  }
  .searchWithinPage .form-control::-moz-placeholder, .searchWithinPage .input-group-btn::-moz-placeholder {
    color: #555 !important;
  }
  .searchWithinPage .form-control::placeholder, .searchWithinPage .input-group-btn::placeholder {
    color: #555 !important;
  }
  .topicSearch .form-control {
    border: 1px solid rgba(0, 0, 0, 0.0745098039) !important;
    border-right: 0 !important;
  }
  .topicSearch .input-group.btn {
    border: 1px solid rgba(0, 0, 0, 0.0745098039) !important;
    border-left: 0 !important;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.08);
  }
  .form-control {
    transition: border-color ease-in-out 0s, box-shadow ease-in-out 0s;
  }
  .footer-bottom .col-xs-12 {
    padding-left: 22px !important;
    padding-right: 22px !important;
  }
  .tgcopyright .col-xs-12 {
    padding-left: 22px !important;
    padding-right: 22px !important;
  }
  .tree-menu {
    height: 100vh !important;
    max-height: 100% !important;
    margin-top: 9px !important;
    z-index: 1000 !important;
    position: absolute;
    top: 96px !important;
  }
  .tree-menu #tocID {
    font-size: 18px !important;
    border-bottom: 1px solid rgba(0, 0, 0, 0.0745098039);
    font-weight: 500;
  }
  .tree-menu #tg_toc_id ditatitle {
    font-size: 18px !important;
    border-bottom: 1px solid rgba(0, 0, 0, 0.0745098039);
    font-weight: 500;
    padding-left: 70px;
    margin-left: -50px;
    padding-right: 20px;
    margin-right: -20px;
  }
  .mobile-navbar {
    display: flex;
    padding: 10px;
  }
  .mobile-navbar {
    display: flex;
    padding: 10px;
  }
  .form-control {
    height: 51px;
  }
  #navbar {
    z-index: 1000 !important;
  }
  #navbar #searchForm {
    max-width: 100% !important;
  }
  #navbar #searchForm input {
    font-size: 18px;
  }
  #navbar #searchForm input::-moz-placeholder {
    color: #777 !important;
  }
  #navbar #searchForm input::placeholder {
    color: #777 !important;
  }
  .dropdown-menu > li > a {
    border: 2px solid rgba(0, 0, 0, 0);
  }
  .greyboxTopic {
    padding: 0px !important;
  }
  .navbar {
    background-color: lightgray;
    top: 0px !important;
    z-index: 1000 !important;
  }
  .navbar-header {
    z-index: 1000 !important;
  }
  #tocbox {
    border-right: 10px solid #ccc !important;
  }
  #tocID {
    font-weight: 700 !important;
    text-indent: 3px;
  }
  #tg_toc_id {
    max-height: 100vh !important;
    height: calc(100vh - 154px) !important;
  }
  div #tocId {
    padding: 0px !important;
  }
  footer {
    background-color: #d3d4d5;
    padding-bottom: 20px;
  }
  #bottomSticky {
    position: fixed;
    bottom: 0px;
    min-height: 65px;
    width: 100%;
    z-index: 998;
  }
  .fa-bars {
    margin-right: -2px !important;
  }
  .searchMobileHidden {
    display: none;
  }
  .searchMobileVisible {
    margin-top: 14px;
    margin-bottom: 8px;
  }
  .mobile-search .fa-search {
    margin-left: -4px !important;
  }
  .topic-tools ul .topic-icons {
    padding: 8px !important;
  }
  #topic-banner {
    font-size: 40px;
  }
  .searchWithinPage {
    padding-right: 0px;
  }
  .mobileContainer {
    padding-left: 0px !important;
    padding-right: 30px !important;
  }
  .slider-arrow {
    width: 25px !important;
    font-size: 15px !important;
    padding: 10px 0 !important;
  }
  .tg-mobile-search, .tg-mobile-menu {
    display: none;
  }
  div#navbar {
    max-height: none !important;
    margin: 12px auto 0 auto;
    width: 100%;
  }
  .container .dart {
    width: 100%;
  }
  .homelink > svg {
    width: 95%;
    max-width: 165px;
  }
  .homelink > svg > image {
    width: 95%;
    max-width: 165px;
  }
  .mobile-logo {
    margin-right: 0 !important;
  }
  .sectionBlockLayout {
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .col-lg-12 {
    padding: 0px;
  }
  .section-heading {
    margin-bottom: 0;
    padding-top: 10px;
    border-bottom: 2px solid rgb(65, 64, 66);
  }
  h4 {
    font-family: DINNeuzeitGrotesk-Light, sans-serif;
    line-height: 24px;
    color: rgb(65, 64, 66);
  }
  .guideline-areas {
    font-size: 16px;
  }
  .guideline-areas > li {
    border-bottom: 1px solid rgb(65, 64, 66);
    padding-top: 12px;
    padding-bottom: 12px;
  }
  .guideline-areas > li > .dotIcon {
    margin-left: 3%;
  }
  .guideline-areas > li > a {
    color: #414042;
    text-decoration: none;
    margin-left: 5%;
  }
  .goto-arrow {
    display: inline;
    font-size: 20px;
    color: #414042;
    margin-right: 5%;
    float: right;
  }
  #navbar-right-section {
    position: relative;
    right: inherit;
    top: 0px;
    width: 100%;
    padding: 0px;
  }
  #navbar-right-section .btn-group {
    margin-right: 10px;
    width: 47%;
  }
}
@media screen and (max-width: 768px) {
  div.epinvisible {
    margin-left: 3px !important;
  }
  .greyboxTopic {
    width: 100%;
    top: 220px;
  }
  .bodyContentDiv {
    width: 100%;
  }
  .etgtopic .pull-right {
    float: right;
  }
  .margin-note-info {
    font-size: 12px;
  }
  .margin-note:before {
    padding-right: 5px;
    vertical-align: baseline;
  }
  .showBox {
    border: 1px solid #000;
    background: #000;
    float: left;
    width: auto;
    height: 45px;
    padding: 5px;
    border-radius: 3px;
  }
  .greyboxTopic {
    width: 100%;
  }
  .ic_list {
    margin-top: -10px !important;
  }
  .ic_list li {
    margin-left: 10px !important;
  }
  .ic_list li a {
    width: 92% !important;
  }
  .overlay {
    top: 0;
    height: 100%;
  }
  .ic_caption {
    top: 0;
    height: 100%;
  }
  .etgtopic {
    font-size: 12px;
    line-height: 16px;
  }
  .etgtopic-banner-head {
    font-size: 32px;
    line-height: 38px;
    margin: 10px 28px 0px 0px;
  }
  .etgtopic h2 {
    font-size: 20px;
    line-height: 24px;
    padding-right: 20px;
    padding-bottom: 8px;
  }
  .etgtopic h2 span {
    padding-left: 0px;
    position: absolute;
    right: 15px;
  }
  .etgtopic h3 {
    font-size: 18px;
    line-height: 22px;
  }
  .etgtopic h4 {
    font-size: 16px;
    line-height: 20px;
  }
  .etgtopic h5 {
    font-size: 14px;
    line-height: 18px;
  }
  .etgtopic h6 {
    font-size: 12px;
    line-height: 16px;
  }
  .etgtopic p, .etgtopic ul, .etgtopic ol,
  .etgtopic ul li, .etgtopic ol li,
  .etgtopic .disp-quote p,
  .etgtopic table p,
  .etgtopic table ul,
  .etgtopic table ol {
    font-size: 12px;
    line-height: 16px;
  }
  .topic-icons {
    display: block;
  }
  .figure {
    display: block;
    max-width: 100%;
    height: auto;
  }
}
@media (max-width: 578px) {
  #tgsearch .filter-desktop {
    display: none !important;
  }
  #tgsearch .result-container {
    display: flex;
  }
  #tgsearch .filter-mobile {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #414042;
    padding-right: 0 !important;
    margin-bottom: 20px;
  }
  #tgsearch .filter-text {
    padding-left: 2%;
  }
  #tgsearch .search-view-tab {
    margin-left: -3%;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 14.5px;
    cursor: pointer;
    padding-top: 20px;
    padding-left: 28px;
    padding-right: 28px;
    padding-bottom: 2px;
  }
}
@media (max-width: 558px) {
  #tgsearch .search-view-tab {
    font-size: 13.5px;
    padding-left: 30px;
    padding-right: 30px;
  }
}
@media (min-width: 481px) and (max-width: 768px) {
  #navbar {
    overflow: hidden;
  }
}
@media screen and (max-width: 480px) {
  .etgtopic .disp-quote .margin30prct {
    margin-left: 60%;
  }
  .etgtopic .disp-quote .margin9prct {
    margin-left: 76px;
  }
  .etgtopic .disp-quote p.margin9prct {
    margin-left: 116px !important;
  }
  .etgtopic-banner-head {
    font-size: 21px;
    line-height: 24px;
    margin-top: 10px;
  }
  .popover {
    max-width: 230px !important;
  }
  .popover-content {
    min-width: 270px !important;
  }
}
@media (min-width: 403px) and (max-width: 558px) {
  #tgsearch .search-view-tab {
    padding-left: 30px;
    padding-right: 30px;
  }
}
@media (max-width: 402px) {
  #tgsearch .search-view-tab {
    padding-left: 11px;
    padding-right: 7px;
  }
}
@media (max-width: 362px) {
  #navbar-right-section .btn-group {
    display: block;
    width: unset;
    margin-right: 0;
  }
}
@media (max-width: 360px) {
  .tree-menu {
    width: calc(100% - 60px) !important;
  }
}
@media screen and (max-width: 350px) {
  .etgtopic-banner-head {
    font-size: 14px;
    line-height: 24px;
    margin-top: 10px;
  }
}
@media (max-width: 350px) {
  .homelink > svg > image {
    width: 92% !important;
  }
}
@media (min-width: 316px) and (max-width: 767px) {
  #tgsearch #sidemenu-container {
    margin-left: 0px !important;
    border-right: 1x solid #ccc !important;
    width: 295px !important;
  }
}
@media (max-width: 300px) {
  .container-center {
    display: flex;
    justify-content: center;
  }
  input[type=text] {
    font-size: 12px !important;
    padding: 11px !important;
  }
  select {
    font-size: 12px !important;
    padding: 2px !important;
  }
}
@media (min-width: 230px) and (max-width: 315px) {
  #tgsearch #sidemenu-container {
    margin-left: 0px !important;
    width: 200px !important;
  }
}